<template>
  <div>
    <div class="text-right mb-1">
      <add-resource
        resource-name="global-EntitiesController"
        add-route-name="add-business"
      />
    </div>

    <b-row>
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="6">
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>

            <b-col md="6">
              <subclass-select
                :subclass.sync="subclass"
                :class-id="2"
              />
            </b-col>

            <b-col md="6">
              <country-select
                :country.sync="country"
                business="true"
                classification="Business"
              />
            </b-col>
            <b-col md="6">
              <state-select
                :state.sync="state"
                :country="country"
                business="true"
                classification="Business"
              />
            </b-col>
            <b-col md="6">
              <city-select
                :city.sync="city"
                :country="country"
                :state="state"
                business="true"
                classification="Business"
              />
            </b-col>

            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <business-table
      ref="parentTable"
      v-bind="{
        fetchData:myProvider,
        pagination,
      }"
    />
    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />

  </div>
</template>

<script>
import AddResource from '@/common/components/common/Table/AddResource.vue'
import BusinessTable from '@/common/components/Business/BusinessTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import CountrySelect from '@/common/components/common/FormInputs/CountrySelect.vue'
import CitySelect from '@/common/components/common/FormInputs/CitySelect.vue'
import StateSelect from '@/common/components/common/FormInputs/StateSelect.vue'
import SubclassSelect from '@/common/components/common/FormInputs/SubclassSelect.vue'
import paginationData from '@/common/compositions/common/paginationData'

export default {
  name: 'AnnouncementsList',
  components: {
    AddResource,
    Search,
    Pagination,
    BusinessTable,
    SearchButton,
    CountrySelect,
    CitySelect,
    StateSelect,
    SubclassSelect,
  },
  data() {
    return {
      searchQuery: '',
      country: '',
      state: '',
      city: '',
      subclass: '',
      loading: true,
    }
  },
  setup() {
    const { pagination } = paginationData()
    return { pagination }
  },
  methods: {
    myProvider() {
      const promise = this.$entities.get('internalops/commercial_entity/search', {
        params: {
          key: this.searchQuery,
          country: this.country,
          city: this.city,
          state: this.state,
          subclassification: this.subclass,
          page_number: this.pagination.currentPage,
          per_page: this.pagination.perPage,
        },
      })
      return promise.then(res => {
        const items = res.data.data.data

        this.pagination.totalRows = res.data.data.total
        return items || []
      }).catch(() => []).finally(() => {
        this.loading = false
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
