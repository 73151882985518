<template>
  <b-card>
    <b-table
      ref="table"
      :no-provider-sorting="true"
      :items="fetchData"
      :fields="tableColumns"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      aria-busy="true"
      class="position-relative"
      responsive
      primary-key="id"
      empty-text="No Business found"
      show-empty
    >
      <!-- Column: User -->
      <template #cell(name)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              size="32"
              :src="`${$entitiesImagesURL}${data.item.logo}`"
              :text="avatarText(data.item.name)"
            />
          </template>
          <div
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.name.slice(0,50) }}
          </div>
          <small class="text-muted">#{{ data.item.id }}</small>
        </b-media>
      </template>
      <!-- Column: Phone -->
      <template #cell(phone)="data">
        {{ data.item.phone_number }}
      </template>
      <!-- Column: City -->
      <template #cell(SubClassification)="data">
        {{ data.item.classification }}
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item
            v-if="$can('show','global-EntitiesController')"
            :to="{ name: 'view-business', params: { id: data.item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update','global-EntitiesController')"
            :to="{ name: 'admin-edit-business', params: { id: data.item.id } }"
          >
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>

          <toggle-status :item="data.item" />

          <b-dropdown-item
            v-if="$can('destroy','global-EntitiesController')"
            @click="deleteRecord(deleteEndPoint,data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import { avatarText } from '@core/utils/filter'
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'
import ToggleStatus from '../common/Organizations/ToggleStatus.vue'

export default {
  name: 'BusinessTable',
  components: { ToggleStatus },
  props: {
    fetchData: { type: Function, default: null },
    pagination: { type: Object, default: () => {} },
  },
  setup() {
    const { deleteRecord, table } = deleteEntityComposition()

    return {
      deleteRecord,
      table,
    }
  },
  data() {
    return {
      tableColumns: [
        { key: 'name' },
        { key: 'email' },
        { key: 'address[0].city', label: 'city' },
        { key: 'status', formatter: v => (v ? 'Active' : 'Inactive') },
        { key: 'updatedAt', label: 'Latest Update' },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ENTITIES_BASE_URL}/internalops/entity/`,
    }
  },
  methods: {
    avatarText,
  },
}
</script>
<style lang="scss" scoped>

</style>
